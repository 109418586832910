<template>
    <div class="custom-content">
        <div class="custom-wrap">
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="任务详情" align="left"></el-table-column>
                <el-table-column prop="fileList" label="短视频（支持拓展名：rar和zip，文件大小不能超过500M" align="left" width="500">
                    <template slot-scope="scope">
                        <div class="item-content">
                            <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                            <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                                   accept="application/zip, .rar" title=""
                                   @change="changeFile($event)">
                            <span class="upload-text" v-if="scope.row.fileName !==''">{{scope.row.fileName}}</span>
                            <div class="percent-box" v-if="scope.row.fileName !==''">
                                <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page-button">
                <el-button type="primary" @click="onSubmit">提交</el-button>
            </div>
        </div>
        <successDialog :successDialogVisible="successDialogVisible" @closeDialog="handleClose"></successDialog>
    </div>
</template>

<script>
    import successDialog from "@/components/successDialog";
    import Upload from '@/utils/slice_upload';
    import {mergeSlice, sliceUpload, stuOpVideoPromoteDetail, stuOpSaveVideoPromote} from '@/utils/apis'

    export default {
        name: "shortVideoAnalysis",
        components: {successDialog},
        data() {
            return {
                customList: [
                    {
                        content: '短视频推广',
                        file: '',
                        fileName: ''
                    },
                ],
                percent: 0,
                successDialogVisible: false,
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
            }

        },
        created() {
            this.getVideoList();
        },
        methods: {
            getVideoList() {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId
                }
                stuOpVideoPromoteDetail(param).then(res => {
                    if (res.data !== null) {
                        this.customList[0].file = res.data.live_path;
                        this.customList[0].fileName = res.data.live_name;
                        this.percent = 100
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            changeFile(event) {
                let file = event.target.files[0];
                let chunkSize = 1024 * 1024 * 8;
                this.$set(this.customList[0], 'fileName', file.name);
                this.uploadInstance = new Upload(file, chunkSize, sliceUpload);
                this.percent = 0;
                let index = 0;
                this.uploadSlice(index);
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.customList[0].file = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.customList[0].file = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            onSubmit() {
                let isErrNum = 0;
                if (this.customList[0].file == '') {
                    isErrNum = 1
                }
                if (isErrNum === 0) {
                    let param = {
                        live_path: this.customList[0].file,
                        live_name: this.customList[0].fileName
                    }
                    if (this.platform == 0) {
                        param.op_id = this.opId;
                        param.course_id = this.courseId
                    }
                    stuOpSaveVideoPromote(param).then(res => {
                        this.successDialogVisible = true
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    this.$message.warning('每项为必填！')
                }
            },
            handleClose() {
                this.successDialogVisible = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 52px;

        .custom-wrap {
            margin: 0 20px;
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            .item-content {
                display: flex;
                align-items: center;

                .divide {
                    width: 20px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 10px;
                }

                .file-upload-btn {
                    display: none;
                }

                .upload-text {
                    margin-left: 11px;
                    font-size: 14px;
                    color: #333333;
                }

                .percent-box {
                    margin-left: 10px;
                    width: 100px;

                    i {
                        color: #2DC079;
                    }
                }
            }

            .page-button {
                text-align: center;
            }
        }
    }
</style>